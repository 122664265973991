// Constants.js
const prod = {
    url: {
        API: 'https://api.takeaseat.io',
        GUEST: 'https://guest.takeaseat.io',
        PUBLIC: 'https://public.takeaseat.io'
    }
};
const dev = {
    url: {
        API: 'http://localhost:3002',
        GUEST: 'http://localhost:3001',
        PUBLIC: 'http://localhost:3003'
    }
};
export const config = process.env.NODE_ENV === 'development' ? dev : prod;
