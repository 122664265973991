// TODO: create shared library with backend
var hmacsha1 = require('hmacsha1');
function currentUnixTimestamp() {
	return (new Date()).getTime();
}
function currentEpoch() {
	return Math.round(currentUnixTimestamp() / (30*1000));
}
// TODO: handle the boundary case when we send the request right at the switch of epoch.
// 	  two options:
// 	    1) Check that we are at the boundary in the front-end and wait
// 	    2) retry exactly once on a 403 result.
function hashPassword(password) {
	return encodeURIComponent(hmacsha1(password, currentEpoch().toString()));
}

module.exports = { hashPassword: hashPassword };
