import React, { Component } from 'react';
import { config } from './Constants';
import { SupportIndexComponent, SupportTicketComponent } from './SupportComponent';
import './App.css';
import { Link } from 'react-router-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { hashPassword } from './utils.js';

class PasswordComponent extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return <div>
			<label htmlFor="master-password">Master password:</label>
			<input  id="master-password" 
				type="password"
				onChange={(e) => this.props.onPasswordChange(e.target.value) }
				value={this.props.password} />
			<button onClick={() => this.props.onLogin()}> Log in</button>
		</div>
	}
}

const Navigation = () => {
	return <ul>
		<li><Link to='/'>Home</Link></li>
		<li><Link to='/support'>Support</Link></li>
		<li><Link to='/newsletter'>Newsletter</Link></li>
		<li><Link to='/analytics'>Analytics</Link></li>
		<li><Link to='/sudo'>Sudo</Link></li>
	</ul>
}

class AnalyticsComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			restaurantVisits: null,
			restaurantGuests: null
		}
	}

	componentDidMount() {
		fetch(`${config.url.API}/root/analytics/restaurant-visits?passwordHash=${hashPassword(this.props.password)}`, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			credentials: "include"
		}).then(data => data.json())
			.then(data => {
				console.log(data)
				this.setState({ restaurantVisits: data });
			})

		fetch(`${config.url.API}/root/analytics/restaurant-guests?passwordHash=${hashPassword(this.props.password)}`, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			credentials: "include"
		}).then(data => data.json())
			.then(data => {
				console.log(data)
				this.setState({ restaurantGuests: data });
			})
	}

	render() {
		if (!this.state.restaurantVisits || !this.state.restaurantGuests)
			return <div></div>

		return <div>
			<h2>Highest Guests</h2>
			<ul>
			{this.state.restaurantGuests.map(row =>
				<li>
				<a href={`https://guest.takeaseat.io/${row.restaurant_id}/menu`}>{row.restaurant_id}
				</a> - {row.total_guests} lifetime guests
				</li>
			)}
			</ul>

			<h2>Highest Visits (including recurring guests)</h2>
			<ul>
			{this.state.restaurantVisits.map(row =>
				<li>
				<a href={`https://guest.takeaseat.io/${row.restaurant_id}/menu`}>{row.restaurant_id}
				</a> - {row.total_visits} total visits
				</li>
			)}
			</ul>
		</div>
	}
}

class NewsletterComponent extends Component {
	constructor(props) {
		super(props);

		this.state = {
			emailSubject: "",
			emailBody: "",
			emailAddresses: "",
			testSent: false
		}
	}

	render() {
		return <div>
			<h1>Newsletter</h1>
			<label>Email subject:</label>
			<input value={this.state.emailSubject} onChange={(e) => { this.setState({emailSubject: e.target.value}); }} />
			<br />
			<label>Email content:</label>
			<textarea 
				value={this.state.emailBody} 
				onChange={(e) => { this.setState({emailBody: e.target.value}); }} 
				rows="10" cols="60" />
			<br />
			<label>Email addresses (comma-separated, must be associated with a valid restaurant):</label>
			<input value={this.state.emailAddresses} onChange={(e) => { this.setState({emailAddresses: e.target.value}); }} />
			<br />

			<button onClick={() => {
				fetch(`${config.url.API}/root/send-email?passwordHash=${hashPassword(this.props.password)}`, {
					method: 'post',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
    					body: JSON.stringify({
			        		subject: this.state.emailSubject,
			        		body: this.state.emailBody,
			        		to: this.state.emailAddresses,
						broadcast: false
    					}),
					credentials: "include"
				}).then(data => data.json()).then(data => {
					console.log(data);
					this.setState({ testSent: true });
				})
			}} style={{marginRight: "1rem"}}>Send</button>

			<button onClick={() => {
				if (!this.state.testSent) {
					return;
				}
				fetch(`${config.url.API}/root/send-email?passwordHash=${hashPassword(this.props.password)}`, {
					method: 'post',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
    					body: JSON.stringify({
				        	subject: this.state.emailSubject,
				        	body: this.state.emailBody,
						broadcast: true
    					}),
					credentials: "include"
				}).then(data => data.json()).then(data => {
					console.log(data);
					this.setState({ testSent: false });
					alert('backend response: ' + data);
				})
			}} disabled={!this.state.testSent}>Broadcast to all restaurants</button>
		</div>
	}
}

class SuDoComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userId: ''
		}
	}

	login() {
		fetch(`${config.url.API}/root/login?passwordHash=${hashPassword(this.props.password)}`, {
			method: 'post',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({ id: this.state.userId }),
			credentials: "include"
		}).then(data => data.json())
		.then(data => {
			alert("result: " + data);
		});
	}

	render() {
		return <div>
			<label htmlFor="user-id">User Id:</label>
			<input id="user-id" type="text" onChange={(e) => { this.setState({ userId: e.target.value}); }} value={this.state.userId} />
			<button onClick={() => { this.login(); }}>Log in</button>
		</div>
	}
}

class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			password: "",
			loggedIn: false
		}

		this.onPasswordChange = this.onPasswordChange.bind(this);
		this.onLogin = this.onLogin.bind(this);
		this.onLogout = this.onLogin.bind(this);
	}

	onPasswordChange(password) {
		this.setState({password});
	}
	onLogin() {
		this.setState({loggedIn: true});
	}
	onLogout() {
		this.setState({loggedIn: false});
	}

	render() {
		if(this.state.loggedIn) {
			return <div>
			<Router>
				<Navigation />
       				<Switch>
			      		<Route exact path="/">
						<SupportIndexComponent password={this.state.password} />
					</Route>
			      		<Route exact path="/support">
						<SupportIndexComponent password={this.state.password} />
					</Route>
			      		<Route exact path="/support/tickets/:ticketId"
						render={(props) => {
							const ticketId = props.match.params.ticketId;
							return <SupportTicketComponent password={this.state.password}
							                               ticketId={ticketId} />
						}} />
			      		<Route exact path="/newsletter">
						<NewsletterComponent password={this.state.password} />
					</Route>
			      		<Route exact path="/analytics">
						<AnalyticsComponent password={this.state.password} />
					</Route>
			      		<Route exact path="/sudo">
						<SuDoComponent password={this.state.password} />
					</Route>
	    			</Switch>
    			</Router>
			</div>
		} else {
			return <PasswordComponent password={this.state.password} onPasswordChange={this.onPasswordChange} onLogin={this.onLogin} />
		}
	}
}


export default App;
