import React, { Component } from 'react';
import { config } from './Constants';
import { hashPassword } from './utils.js';
import { Link } from 'react-router-dom';

class SupportIndexComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tickets: null,
			status: "open"
		}

		this.loadData = this.loadData.bind(this);
	}

	loadData(status) {
		fetch(`${config.url.API}/root/support/tickets?status=${status}&passwordHash=${hashPassword(this.props.password)}`, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			credentials: "include"
		}).then(data => data.json())
		.then(data => {
			this.setState({ tickets: data, status: status });
		})
	}

	componentDidMount() {
		this.loadData("open");
	}

	render() {
		if (!this.state.tickets) {
			return <div />
		}
		return <div>
			<h1>Support</h1>
			<ul>
				<li><a href="#" className={ this.state.status == "open" ? "active" : null } onClick={() => { this.loadData("open") }}>Open</a></li>
				<li><a href="#" className={ this.state.status == "waiting" ? "active" : null } onClick={() => { this.loadData("waiting") }}>Waiting</a></li>
				<li><a href="#" className={ this.state.status == "closed" ? "active" : null } onClick={() => { this.loadData("closed") }}>Closed</a></li>
			</ul>
			<ul>{this.state.tickets.map(ticket =>
				<li>
				  <Link to={`/support/tickets/${ticket.id}`}>Ticket #{ticket.id} - {ticket.title}</Link> (<b>{ticket.category}</b>) <br />
				  customer <a href={`https://guest.takeaseat.io/${ticket.restaurant_id}`}>{ticket.restaurant_id}</a>
				</li>)}
			</ul>
		</div>
	}
}

class SupportTicketComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: null,
			postMessage: ""
		};

		this.loadData = this.loadData.bind(this);
	}

	loadData() {
		fetch(`${config.url.API}/root/support/tickets/${this.props.ticketId}?passwordHash=${hashPassword(this.props.password)}`, {
			method: 'get',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			credentials: "include"
		}).then(data => data.json())
			.then(data => {
		  		this.setState({ ticket: data });
			})
	}

	componentDidMount() {
		this.loadData();
	}

	render() {
		if (!this.state.ticket)
			return <div />;


		const Post = function(props) {
			const post = props.post;
			const date = new Date(post.posted_time).toLocaleString();
			return <li>
				<p>{post.message}</p>
				<p>Posted by <b>{post.source}</b> at <i>{date}</i></p>
				<hr />
			</li>
		}

		return <div>
			<h1>Ticket #{this.state.ticket.id}</h1>
			<p><i>Question filed by <a href={"https://guest.takeaseat.io/${this.state.ticket.restaurant_id"}>{this.state.ticket.restaurant_id}</a> in category <b>{this.state.ticket.category}</b></i></p>
			<p>Status: <b>{this.state.ticket.status}</b> <i>
			(A request status is not visible to the customer but helps you track the status of issues.)</i>
			</p>

			<h2>{this.state.ticket.title}</h2>
			<p>{this.state.ticket.message}</p>

			<h2>Updates</h2>
			<ul>{ this.state.ticket.posts.map(post => <Post post={post} />) }</ul>

			<h2>Post update</h2>
			<textarea 
				value={this.state.postMessage} 
				onChange={(e) => { this.setState({postMessage: e.target.value}); }} 
				rows="10" cols="60" />
			<br />
			<button onClick={() => {
				fetch(`${config.url.API}/root/support/tickets/${this.state.ticket.id}/posts?passwordHash=${hashPassword(this.props.password)}`, {
					method: 'post',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
    					body: JSON.stringify({
			        		message: this.state.postMessage
    					}),
					credentials: "include"
				}).then(data => data.json()).then(data => {
					console.log(data);
					this.setState({ postMessage: "" });
					this.loadData();
				})
			}} style={{ margin: "1rem" }}>Post update</button>
			<br />
			<button onClick={() => {
				fetch(`${config.url.API}/root/support/tickets/${this.state.ticket.id}/status?passwordHash=${hashPassword(this.props.password)}`, {
					method: 'post',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
    					body: JSON.stringify({
						status: (this.state.ticket.status == "closed" ? "open" : "closed")
    					}),
					credentials: "include"
				}).then(data => data.json()).then(data => {
					console.log(data);
					this.loadData();
				})

			}} style={{ margin: "1rem" }}>{ this.state.ticket.status == "closed" ? "Re-open" : "Close" } Request</button>
		</div>
	}
}

export { SupportIndexComponent, SupportTicketComponent };
